import { useState, useEffect, useRef, useMemo } from "react";
import Intro from "./containers/Intro";
import { CSSTransition } from "react-transition-group";
import imgUp from './img/up-chevron-32.png';
import imgDown from './img/down-chevron-32.png';
import logo from './img/iCoWork_150_white.png';

export default function App() {
  const [inTransition, setInTransition] = useState(false);
  const [page, setPage] = useState(0);
  const lastY = useRef(0);
  const isScrolling = useRef(false);

  function scrollhandler(e) {
    console.log("onWheel fired");
    console.log(page);
    //let currentY = e.deltaY;
    console.log(e.deltaY);
    if (page >= 0 && page < pages.length) {
      if (e.deltaY > 0 && page < pages.length - 1) {
        setPage((prev) => prev + 1);
      } else if (page > 0) {
        setPage((prev) => prev - 1);
      }
    }
    //lastY.current = currentY;
  }

  const pages = [
    {
      title: "Welcome",
      content: (
        <div>
          <h1>iCoWork</h1>
          <h2>Your Online CoWorking Space</h2>
          <p></p>
        </div>
      )
    },
    {
      title: "Vision",
      content: (
        <div>
          <h1>Vision</h1>
          <div className={"quote"}>
            <div>&bdquo;Creating an international and diverse co-working community that reduces distance, emphesizes productivity and health and makes you feel connected with the world.&ldquo;</div>
          </div>
        </div>
      )
    },
    {
      title: "Mission",
      content: (
        <div>
          <h1>Mission</h1>
          <div className={"quote"}>
            <div>&bdquo;Making remote work a bit better every day.&ldquo;</div>
          </div>
        </div>
      )
    },
    {title: "Join now", content: (<div>
      <h2>Join now - it's free</h2>
      <div className={"withmargin"}>
        <a href="https://now.icowork.online">now.icowork.online</a>
      </div>
    </div>)},
    {title: "Legal", content: (<div>
      <h2>Legal</h2>
      <div className={"outrolinks"}>  
          <a className={"outrolink"} href="http://icoworktermsofuse.s3-website.eu-central-1.amazonaws.com/" rel="nofollow" target="_blank">terms of use</a> &nbsp;
          <a className={"outrolink"} href="http://icoworkprivacy.s3-website.eu-central-1.amazonaws.com/" rel="nofollow" target="_blank">privacy policy</a>  &nbsp;
          <a className={"outrolink"} href="http://icoworkimprint.s3-website.eu-central-1.amazonaws.com/" rel="nofollow" target="_blank">imprint</a>
          </div>
      </div>)}
  ];

  const getPageContainer = () => {
    if (page < pages.length) {
      return pages[page];
    } else {
      return { title: "Default", content: <></> };
    }
  };

  function touchStart(e) {
    console.log("touchStart");
    console.log(e);
    if (page >= 0 && page < pages.length) {
      lastY.current = e.changedTouches[0].pageY;
      setInTransition(true);
    }
  }

  function touchEnd(e) {
    console.log("touchEnd");
    console.log(e);
    if (page >= 0 && page < pages.length) {
      let currentY = e.changedTouches[0].pageY;
      if (currentY < lastY.current && page < pages.length - 1) {
        // moved down
        setPage((prev) => prev + 1);
      } else if (currentY > lastY.current && page > 0) {
        // moved up
        setPage((prev) => prev - 1);
      }
    }
    setInTransition(false);
  }

  const buttonNavigation = (direction, limit) => {
    if (page >= 0 && page < pages.length) {
      if (isScrolling.current === false) {
        if (direction === "down" && page < pages.length - 1) {
          console.log("scroll to next page");
          isScrolling.current = true;
          setInTransition(true);
          setPage((prev) => prev + 1);

          let lastFunc = setTimeout(() => {
            isScrolling.current = false;
            setInTransition(false);
          }, limit);
        } else if (direction === "up" && page > 0) {
          console.log("scroll to prev page");
          isScrolling.current = true;
          setInTransition(true);
          setPage((prev) => prev - 1);

          let lastFunc = setTimeout(() => {
            isScrolling.current = false;
            setInTransition(false);
          }, limit);
        }
      }
    }
  };

  const throttle = (e, limit) => {
    console.log("In throttle");
    console.log(page);

    if (page >= 0 && page < pages.length) {
      if (isScrolling.current === false) {
        if (e.deltaY > 0 && page < pages.length - 1) {
          console.log("scroll to next page");
          isScrolling.current = true;
          setInTransition(true);
          setPage((prev) => prev + 1);

          let lastFunc = setTimeout(() => {
            isScrolling.current = false;
            setInTransition(false);
          }, limit);
        } else if (e.deltaY < 0 && page > 0) {
          console.log("scroll to prev page");
          isScrolling.current = true;
          setInTransition(true);
          setPage((prev) => prev - 1);

          let lastFunc = setTimeout(() => {
            isScrolling.current = false;
            setInTransition(false);
          }, limit);
        }
      }
    }
  };

  return (
    <>
      <div
        className="App"
        onWheel={(e) => throttle(e, 3000)}
        onTouchStart={(e) => touchStart(e)}
        onTouchEnd={(e) => touchEnd(e)}
      >
        <header>
          <img className="image" src={logo} />
          <div className={"headerlinks"}>
          <a className={"cta"} href="https://now.icowork.online/"><b>start coworking</b></a> &nbsp;  
          <a className={"headerlink"} href="http://icoworktermsofuse.s3-website.eu-central-1.amazonaws.com/" rel="nofollow" target="_blank">terms of use</a> &nbsp;
          <a className={"headerlink"} href="http://icoworkprivacy.s3-website.eu-central-1.amazonaws.com/" rel="nofollow" target="_blank">privacy policy</a>  &nbsp;
          <a className={"headerlink"} href="http://icoworkimprint.s3-website.eu-central-1.amazonaws.com/" rel="nofollow" target="_blank">imprint</a>
          </div>
        </header>
        <CSSTransition
          in={inTransition}
          timeout={3000}
          classNames="section"
          onEnter={() => setInTransition(true)}
          onExited={() => setInTransition(false)}
        >
          <div className={"content"}>
            <div>
            {page > 0 ? (
                <img
                  alt="scroll up"
                  className="animated"
                  src={imgUp}
                  onClick={() => buttonNavigation("up", 3000)}
                />
            ) : (
              <></>
            )}
            </div>
            <div className={"test"}>{getPageContainer(page).content}</div>

            <div>
            {page < pages.length - 1 ? (
              
                <img
                  alt="scroll down"
                  className="animated"
                  src={imgDown}
                  onClick={() => buttonNavigation("down", 3000)}
                />
            ) : (
              <></>
            )}
            </div>
          </div>
        </CSSTransition>
      </div>
    </>
  );
}
